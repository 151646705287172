import React from 'react';
import PropTypes from 'prop-types';

import { Seo, Button } from '../../components';
import NotFoundImg from '../../media/images/404.png';

import styles from './404.module.css';

export const NotFound = ({
    data: {
        contentful: {
            notFoundPageCollection: {
                items: [page],
            },
        },
    },
}) => {
    const {
        tabTitle,
        text,
        buttonText,
    } = page;

    return (
        <>
            <Seo title={ `${tabTitle} - PA Advogados` } />
            <main className={ styles.main }>
                <section className={ styles.error }>
                    <img
                        tabIndex="-1"
                        src={ NotFoundImg }
                        draggable={ false }
                        className={ styles.notFoundImg } />
                    <p>{ text }</p>
                    <Button className={ styles.button } to="/">
                        { buttonText }
                    </Button>
                </section>
            </main>
        </>
    );
};

NotFound.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            notFoundPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default NotFound;
